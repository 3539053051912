import React from 'react';
import PageHeader from 'components/global/PageHeader';
import SEO from 'components/global/Seo';
import MeasurementChart from 'components/measurement-chart/MeasurementChart';

import IconTape from 'assets/svgs/tape.svg';
const MasstabellePage = () => {
  return (
    <>
      <SEO
        title="Maßtabelle"
        description="Hier findest du unsere Größen und Anleitungen zum Nachmessen, damit dein Pferd die richtige Größe trägt. "
      />
      <PageHeader
        title="Maßtabelle"
        text="Hier findest du unsere Größen und Anleitungen zum Nachmessen, damit dein Pferd die richtige Größe trägt. "
        icon={<IconTape />}
      />
      <MeasurementChart />
    </>
  );
};

export default MasstabellePage;
