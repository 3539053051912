import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Box, Container, Paragraph, Link } from 'theme-ui';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import type { Options } from '@contentful/rich-text-react-renderer';
import type { BannerQuery } from 'types/graphql';
import type { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';

const Banner = () => {
  const richTextOptions: Options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Paragraph
          sx={{
            textAlign: 'center',
          }}
        >
          {children}
        </Paragraph>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Link
          color="black"
          sx={{
            textDecoration: 'underline',
          }}
          href={node.data.uri}
        >
          {children}
        </Link>
      ),
    },
  };

  const { contentfulBanner } = useStaticQuery<BannerQuery>(graphql`
    query Banner {
      contentfulBanner {
        show
        text {
          raw
        }
      }
    }
  `);

  if (!contentfulBanner?.show || !contentfulBanner?.text) return null;

  const richText = contentfulBanner.text as RenderRichTextData<ContentfulRichTextGatsbyReference>;

  return (
    <Box
      bg="pink"
      sx={{
        pt: 'env(safe-area-inset-top)',
      }}
    >
      <Box py={[2, null, 3]} bg="pink">
        {contentfulBanner.text && <Container>{renderRichText(richText, richTextOptions)}</Container>}
      </Box>
    </Box>
  );
};

export default Banner;
