import React from 'react';
import { graphql } from 'gatsby';
import SEO from 'components/global/Seo';
import CategoryPageHeader from 'components/shop/CategoryPageHeader';
import CategoryProductList from 'components/shop/CategoryProductList';

import type { CategoryPageQuery } from 'types/graphql';
import type { PageProps } from 'gatsby';

const CategoryPage: React.FC<PageProps<CategoryPageQuery>> = ({ data }) => {
  return (
    <>
      <SEO
        title={data.bigCommerceCategories?.name || ''}
        description={data.bigCommerceCategories?.meta_description || ''}
        image={data.bigCommerceCategories?.image_url || ''}
        pathname={data.bigCommerceCategories?.custom_url?.url || ''}
      />
      <CategoryPageHeader
        title={data.bigCommerceCategories?.name}
        description={data.bigCommerceCategories?.description}
        gatsbyImage={data.bigCommerceCategories?.image_sharp?.childImageSharp?.gatsbyImageData}
      />
      <CategoryProductList category={data.bigCommerceCategories} />
    </>
  );
};

export const query = graphql`
  query CategoryPage($id: Int!) {
    bigCommerceCategories(bigcommerce_id: { eq: $id }) {
      bigcommerce_id
      name
      description
      meta_description
      custom_url {
        url
      }
      image_url
      image_sharp {
        childImageSharp {
          gatsbyImageData(width: 1200)
        }
      }
    }
  }
`;

export default CategoryPage;
