import React from 'react';
import { graphql } from 'gatsby';
import SEO from 'components/global/Seo';
import Legal from 'components/legal/Legal';

import type { LegalPageQuery } from 'types/graphql';
import type { PageProps } from 'gatsby';

const LegalPage: React.FC<PageProps<LegalPageQuery>> = ({ data }) => {
  return (
    <>
      <SEO title={data.contentfulLegal?.title || ''} robots="noindex" />
      <Legal text={data.contentfulLegal?.text || ''} />
    </>
  );
};

export const query = graphql`
  query LegalPage($slug: String!) {
    contentfulLegal(slug: { eq: $slug }) {
      title
      text {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export default LegalPage;
