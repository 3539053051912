/** @jsx jsx */
import { jsx, Container, Grid, Flex, Heading, useThemeUI } from 'theme-ui';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { Appear } from 'components/global/utils/Animations';
import ProductListItem from 'components/global/ProductListItem';
import Button from 'components/global/Button';

import { FeaturedProductsQuery } from 'types/graphql';

const FeaturedProducts = () => {
  const {
    allBigCommerceProducts: { nodes: featuredProducts },
  } = useStaticQuery<FeaturedProductsQuery>(graphql`
    query FeaturedProducts {
      allBigCommerceProducts(filter: { is_featured: { eq: true } }) {
        nodes {
          id
          price
          sale_price
          name
          is_featured
          images {
            image_sharp {
              childImageSharp {
                gatsbyImageData(width: 240)
              }
            }
            is_thumbnail
            description
          }
          custom_url {
            url
          }
        }
      }
    }
  `);

  const { theme } = useThemeUI();

  return (
    <section sx={{ marginBottom: 6 }}>
      <Appear>
        <Heading sx={{ textAlign: 'center' }}>Lieblingsstücke</Heading>
      </Appear>

      <Container>
        <Grid
          columns={[2, 3]}
          gap={[4, null, 5]}
          sx={{
            maxWidth: theme.breakpoints?.[2],
            mx: 'auto',
            mb: [5, null, 6],
          }}
        >
          {featuredProducts.map((product) => (
            <Appear key={product.id}>
              <ProductListItem
                title={product.name}
                gatsbyImage={
                  product.images?.filter((image) => image?.is_thumbnail).pop()?.image_sharp?.childImageSharp
                    ?.gatsbyImageData
                }
                alt={product.images?.filter((image) => image?.is_thumbnail).pop()?.description}
                price={product.price}
                salePrice={product.sale_price}
                link={product?.custom_url?.url ? `/shop${product.custom_url.url}` : '/shop'}
              />
            </Appear>
          ))}
        </Grid>
        <Flex
          sx={{
            justifyContent: 'center',
            marginTop: 4,
          }}
        >
          <Appear>
            <Button as={Link} to="/shop">
              Weitere Produkte ansehen
            </Button>
          </Appear>
        </Flex>
      </Container>
    </section>
  );
};

export default FeaturedProducts;
