/** @jsx jsx */
import React from 'react';
import { jsx, Button } from 'theme-ui';

type ButtonProps = {
  children: React.ReactNode;
  as?: React.ElementType<any>;
  variant?: string;
  to?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonComponent: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  as = 'button',
  to,
  onClick,
  ...rest
}) => (
  <Button
    onClick={onClick}
    as={as}
    variant={variant}
    /* @ts-ignore:disable-next-line */
    // TODO: Fix type
    to={to}
    {...rest}
  >
    {children}
  </Button>
);

export default ButtonComponent;
