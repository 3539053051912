/** @jsx jsx */
import { jsx, Container, Grid, Heading, Paragraph, BaseStyles, Text, Box, Link } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

import type { MeasurementImagesQuery } from 'types/graphql';
import type { ImageSharp } from 'types/graphql';

const MeasurementChart = () => {
  const { measureHolster, measureCaveson } = useStaticQuery<MeasurementImagesQuery>(graphql`
    query MeasurementImages {
      measureHolster: file(relativePath: { eq: "images/measure-holster.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 400)
        }
      }
      measureCaveson: file(relativePath: { eq: "images/measure-caveson.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 400)
        }
      }
    }
  `);
  return (
    <Container>
      <BaseStyles>
        <div sx={{ width: '100%', overflow: 'auto' }}>
          <table
            sx={{
              width: '100%',
              whiteSpace: 'nowrap',
              overflow: 'auto',
              border: '1px solid darkGrey',
              borderCollapse: 'separate',
              tableLayout: 'fixed',
              borderSpacing: 0,
              'td, th': {
                border: '1px solid darkGrey',
                padding: '0.5rem 1rem',
              },
              td: {
                background: '#fff',
                padding: '4px 5px',
                textAlign: 'center',
              },
              'thead th': {
                '&:first-of-type': {
                  position: 'sticky',
                  left: 0,
                  zIndex: 2,
                  width: '3rem',
                },
                padding: '3px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
                width: '10rem',
                background: 'white',
              },
              'tbody th': {
                position: 'sticky',
                left: 0,
                background: 'white',
                zIndex: 1,
              },
            }}
          >
            <thead>
              <tr>
                <th></th>
                <th>Minishetty</th>
                <th>Shetty</th>
                <th>Pony</th>
                <th>Vollblut/Cob</th>
                <th>Warmblut</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>A</th>
                <td>18</td>
                <td>20</td>
                <td>22</td>
                <td>23</td>
                <td>25</td>
              </tr>
              <tr>
                <th>B</th>
                <td>14</td>
                <td>17</td>
                <td>21</td>
                <td>23</td>
                <td>24</td>
              </tr>
              <tr>
                <th>C</th>
                <td>16,5</td>
                <td>18</td>
                <td>21</td>
                <td>21</td>
                <td>23</td>
              </tr>
              <tr>
                <th>D</th>
                <td>9</td>
                <td>10</td>
                <td>14</td>
                <td>17</td>
                <td>18</td>
              </tr>
              <tr>
                <th>E</th>
                <td>15</td>
                <td>15</td>
                <td>18</td>
                <td>23</td>
                <td>23</td>
              </tr>
              <tr>
                <th>F</th>
                <td>70</td>
                <td>70</td>
                <td>80</td>
                <td>80</td>
                <td>80</td>
              </tr>
            </tbody>
          </table>
        </div>
      </BaseStyles>
      <Text
        sx={{
          fontStyle: 'italic',
          display: 'block',
          mb: 5,
        }}
      >
        Angaben in cm
      </Text>

      <ImageText
        heading="Knotenhalfter"
        gatsbyImage={measureHolster?.childImageSharp?.gatsbyImageData}
        alt="Pferd mit Halfter und Erklärung wie zu messen ist"
      >
        <HeadingText
          heading="Nasenrücken (A)"
          text="Lege zwei Finger unter das Jochbein des Pferdes. Dort legst du dein Maßband an und führst es über den
            Nasenrücken zum Jochbein auf der anderen Seite."
        />
        <HeadingText
          heading="Backenstück (B)"
          text="Wieder startest du mit zwei Fingern unter dem Jochbein. Von dort misst du in einer geraden Linie bis zum
            Knochen hinter dem Auge deines Pferdes."
        />
        <HeadingText
          heading="Kinn (C)"
          text="Du legst wieder zwei Finger unter das Jochbein und misst von da bis zur Mitte des Kinns deines Pferdes."
        />
        <HeadingText
          heading="Kehlriemen (D)"
          text="Von der Mitte des Kinns misst du gerade bis zum Endpunkt des Ganaschen Riemens (Mitte der Kehle)."
        />
        <HeadingText
          heading="Ganasche (E)"
          text="Am Ende des Backenstücks setzt du dein Maßband an und misst entlang der Ganasche bis zur Mitte der Kehle."
        />
        <HeadingText
          heading="Genick (F)"
          text="Ausgangspunkt ist dein gemessenes Ende des Backenstücks. Vor dort misst du über das Genick (hinter den Ohren vorbei) auf gegenüberliegende Seite, sodass du dort ebenfalls auf Höhe des Endes des Backenstücks ankommst. Wir berechnen in unseren Maßen das ganze Seil, welches am letztlich das Genickstück und damit das Seil zum zuknoten des Knotenhalfters bildet."
        />
      </ImageText>

      <ImageText
        heading="Kappzaum"
        gatsbyImage={measureCaveson?.childImageSharp?.gatsbyImageData}
        alt="Pferd mit Kappzaum und Erklärung wie zu messen ist"
      >
        <HeadingText
          heading="Nasenstück (A)"
          text="2 Finger breit unter dem Jochbein über die Nase auf die gegenüberliegende Seite auf dieselbe Höhe (2 Finger-breit unterhalb des Jochbeins)."
        />
        <HeadingText
          heading="Nasenstück und Kinnriemen (B)"
          text="Einmal komplett um die Pferdenase herum messen etwa 2 Finger-breit unterhalb des Jochbeins. "
        />
        <HeadingText
          heading="Backenstück (C)"
          text="Gemessen von der Mitte der Ganasche bis etwa 2 Finger-breit unterhalb des Jochbeins."
        />
        <HeadingText
          heading="Ganasche (D)"
          text="Von der Mitte der Ganasche rechts zur Mitte der Ganasche links unter dem Kopf entlang. "
        />
        <HeadingText
          heading="Genickstück (E)"
          text="Von der Mitte der Ganasche rechts zur Mitte der Ganasche links hinter den Ohren entlang."
        />
      </ImageText>
      <Heading as="h2" variant="headingMd">
        Info
      </Heading>
      <Paragraph>
        Dein Pferd liegt oft zwischen den Größen oder hat einen sehr großen Kopf? Schreib uns per Email an{' '}
        <Link href="mailto:info@equidreams.de">info@equidreams.de</Link> oder auf Instagram eine Nachricht, damit wir
        dir eine Maßanfertigung anbieten können.
      </Paragraph>
    </Container>
  );
};

type HeadingTextProps = {
  heading: string;
  text: string;
};

const HeadingText: React.FC<HeadingTextProps> = ({ heading, text }) => (
  <Fragment>
    <Heading as="h3" variant="headingSm">
      {heading}
    </Heading>
    <Paragraph sx={{ mb: 4 }}>{text}</Paragraph>
  </Fragment>
);

type ImageTextProps = {
  heading: string;
  gatsbyImage: ImageSharp['gatsbyImageData'];
  alt: string;
};

const ImageText: React.FC<ImageTextProps> = ({ children, heading, gatsbyImage, alt }) => (
  <Box sx={{ mb: 5 }}>
    <Heading
      as="h2"
      variant="headingMd"
      sx={{
        mb: 4,
      }}
    >
      {heading}
    </Heading>
    <Grid
      columns={[1, 2, '2fr 3fr']}
      gap={[4, 5, null, 6]}
      sx={{
        alignItems: 'flex-start',
      }}
    >
      <GatsbyImage
        image={gatsbyImage}
        alt={alt}
        sx={{
          borderRadius: 1,
          boxShadow: 'image',
          objectFit: 'cover',
          zIndex: 1,
        }}
      />
      <div>{children}</div>
    </Grid>
  </Box>
);

export default MeasurementChart;
