/** @jsx jsx */
import { jsx, Container, Box, Grid } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';
import ProductListItem from 'components/global/ProductListItem';

import type { ProductsQuery, CategoryPageQuery } from 'types/graphql';

type CategoryProductListProps = {
  category: CategoryPageQuery['bigCommerceCategories'];
};

const CategoryProductlist: React.FC<CategoryProductListProps> = ({ category }) => {
  const {
    allBigCommerceProducts: { nodes },
  } = useStaticQuery<ProductsQuery>(graphql`
    query Products {
      allBigCommerceProducts {
        nodes {
          id
          price
          sale_price
          name
          images {
            image_sharp {
              childImageSharp {
                gatsbyImageData(width: 270)
              }
            }
            is_thumbnail
            description
          }
          custom_url {
            url
          }
          categories
        }
      }
    }
  `);

  const products = nodes.filter((product) => product?.categories?.includes(category?.bigcommerce_id || 0));

  return (
    <Container>
      <Grid columns={[2, 3, 4]} gap={[4, null, null, 5]}>
        {products.map((product) => (
          <Box key={product.id}>
            <ProductListItem
              title={product.name}
              gatsbyImage={
                product.images?.filter((image) => image?.is_thumbnail).pop()?.image_sharp?.childImageSharp
                  ?.gatsbyImageData
              }
              alt={product?.images?.filter((image) => image?.is_thumbnail).pop()?.description}
              price={product.price}
              salePrice={product.sale_price}
              link={product?.custom_url?.url ? `/shop${product.custom_url.url}` : '/shop'}
            />
          </Box>
        ))}
      </Grid>
    </Container>
  );
};

export default CategoryProductlist;
