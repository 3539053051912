/** @jsx jsx */
import { jsx, Container, Flex, Text, Heading, Box, Theme, get } from 'theme-ui';

import React, { cloneElement, ReactElement } from 'react';

type PageHeaderProps = {
  title: string;
  text: string;
  icon: ReactElement;
};

const PageHeader: React.FC<PageHeaderProps> = ({ title, text, icon }) => {
  return (
    <section
      sx={{
        background: (t: Theme) => get(t, 'gradients.hero'),
        paddingTop: ['4rem', null, '6rem'],
        paddingBottom: ['1rem', null, '3rem'],
        marginBottom: 4,
      }}
    >
      <Container>
        <Box
          sx={{
            position: 'relative',
            maxWidth: ['30rem', '50rem'],
            width: '80%',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              right: '-15%',
              top: 0,
              height: ['9rem', null, '12rem'],
            }}
          >
            {cloneElement(icon, {
              style: {
                height: '100%',
                transform: 'rotate(13deg)',
              },
            })}
          </Box>
          <Heading
            as="h1"
            sx={{
              position: 'relative',
              fontSize: [4, null, 6],
              mb: [4, 4, 4],
            }}
          >
            {title}
          </Heading>
          <Text variant="subheading" sx={{ position: 'relative' }}>
            {text}
          </Text>
        </Box>
      </Container>
    </section>
  );
};

export default PageHeader;
