/** @jsx jsx */
import { jsx, Container, Grid, Flex, Text, Heading, Paragraph } from 'theme-ui';
import SEO from 'components/global/Seo';
import { Fragment } from 'react';
import Button from 'components/global/Button';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <Fragment>
    <SEO title="Nicht gefunden" />
    <Container sx={{ mt: '9rem' }}>
      <Heading>Nicht gefunden</Heading>
      <Paragraph sx={{ mb: 4 }}>Diese Seite existiert leider nicht.</Paragraph>
      <Button as={Link} to="/shop">
        Zurück zum Shop
      </Button>
    </Container>
  </Fragment>
);

export default NotFoundPage;
