/** @jsx jsx */
import { jsx, Container, BaseStyles } from 'theme-ui';

import { renderRichText } from 'gatsby-source-contentful/rich-text';

import { BLOCKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';

type LegalProps = {
  text: any;
};

const richTextOptions: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => <img src={node.data.target.file.url} alt={node.data.target.title} />,
  },
};

const Legal: React.FC<LegalProps> = ({ text }) => {
  return (
    <Container>
      <BaseStyles
        sx={{
          pt: '10rem',
          h2: {
            variant: 'text.headingMd',
            pt: 4,
          },
          img: {
            maxWidth: ['20rem', '30rem'],
          },
        }}
      >
        {renderRichText(text, richTextOptions)}
      </BaseStyles>
    </Container>
  );
};

export default Legal;
