import { Theme, get } from 'theme-ui';

import type { Property } from 'csstype';
import type { StylePropertyValue } from 'theme-ui';

declare module 'theme-ui' {
  interface Theme {
    gradients: Record<string, StylePropertyValue<Property.Background<string>>>;
  }
}

const theme: Theme = {
  breakpoints: ['576px', '768px', '992px', '1200px', '1400px'],
  layout: {
    container: {
      px: [4, null, null, 5],
      maxWidth: '1200px',
    },
  },
  space: [0, '0.25rem', '0.5rem', '1rem', '2rem', '4rem', '7rem'],
  fonts: {
    body: 'Source Sans Pro, Verdana, Geneva, Tahoma, sans-serif',
    heading: 'Source Serif Pro, Times New Roman, Times, serif',
  },
  fontSizes: ['1.2rem', '1.6rem', '1.8rem', '2.2rem', '3rem', '3.5rem', '4rem'],
  radii: ['1rem', '2rem', '3rem'],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 600,
  },
  shadows: {
    image: '0px 4px 30px rgba(137, 71, 118, 0.3)',
    imageSmall: '0px 2px 15px rgba(137, 71, 118, 0.2)',
    heading: '0px 3px 8px rgba(0, 0, 0, 0.15)',
    button: '0px 2px 13px #ffd9f9',
    buttonHover: '0px 2px 13px 3px #ffd9f9',
    input: '0px 3px 8px rgba(0, 0, 0, 0.18)',
  },
  lineHeights: {
    body: 1.8,
    heading: 1.2,
  },
  buttons: {
    primary: {
      fontSize: [1, null, 2],
      borderRadius: 2,
      boxShadow: 'button',
      color: 'black',
      background: (t) => get(t, 'gradients.button'),
      backgroundSize: '300% 100%',
      padding: '1.4rem 4rem',
      transition: '0.4s ease-in-out',
      '&:hover, &:active': {
        boxShadow: 'buttonHover',
        backgroundPosition: '100% 0',
      },
    },
    secondary: {
      variant: 'buttons.primary',
      background: (t) => get(t, 'gradients.buttonSecondary'),
    },
    noStyle: {
      border: 'none',
      padding: 0,
      background: 'none',
      lineHeight: 0,
      cursor: 'pointer',
    },
    select: {
      py: '0.3rem',
      px: 3,
      borderRadius: 1,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'lightGray',
      color: 'black',
    },
  },
  text: {
    default: {
      fontSize: [1, null, 2],
    },
    paragraph: {
      variant: 'text.default',
    },
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      textShadow: 'heading',
      fontSize: [4, null, 5],
      marginTop: 0,
      marginBottom: [4, 5],
    },
    subheading: {
      color: 'black',
      marginBottom: 30,
      textShadow: 'heading',
      fontSize: [2, null, 3],
    },
    headingSm: {
      color: 'darkGray',
      fontFamily: 'body',
      fontWeight: 'bold',
      fontSize: [1, null, 2],
      marginTop: 0,
      marginBottom: 0,
    },
    headingMd: {
      color: 'black',
      fontSize: [3, 4],
      lineHeight: 'heading',
      fontFamily: 'body',
      fontWeight: 'bold',
      marginTop: 0,
      marginBottom: [2, 4],
    },
    error: {
      color: 'error',
      fontSize: 0,
      marginBottom: 3,
    },
    sale: {
      textDecoration: 'line-through',
      color: 'darkPink',
    },
    legal: {
      fontSize: [0, null, 1],
      color: 'darkGray',
    },
  },
  colors: {
    black: '#393939',
    darkGray: '#585858',
    midGray: '#737373',
    lightGray: '#dcdcdc',
    purple: '#f5e3ff',
    pink: '#ffc6ee',
    darkPink: '#c839a0',
    gradientPrimary1: '#FFF4FD',
    gradientPrimary2: '#FFFBF4',
    background: '#ffffff',
    error: 'red',
  },
  gradients: {
    primary: (t) =>
      `radial-gradient(98% 269% at 1% 0%, ${get(t, 'rawColors.gradientPrimary1')} 39%, ${get(
        t,
        'rawColors.gradientPrimary2',
      )} 100%)`,
    hero: (t) =>
      /* @ts-ignore:disable-next-line */
      // TODO: Fix type
      `linear-gradient(180deg, #ffffff00 93%, ${get(t, 'rawColors.background')} 100%), ${t.gradients.primary(t)}`,
    button: (t) =>
      `linear-gradient(95deg, ${get(t, 'rawColors.purple')}, ${get(t, 'rawColors.pink')}, ${get(
        t,
        'rawColors.purple',
      )})`,
    buttonSecondary: (t) =>
      `linear-gradient(95deg,${get(t, 'rawColors.background')}, ${get(t, 'rawColors.gradientPrimary2')}, ${get(
        t,
        'rawColors.background',
      )})`,
  },

  forms: {
    label: {
      variant: 'text.headingSm',
      textShadow: 'heading',
      mb: 2,
      fontWeight: 'bold',
    },
    fieldset: {
      border: 'none',
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      padding: 0,
    },
    input: {
      variant: 'text.default',
      borderRadius: 0,
      padding: 3,
      borderColor: 'lightGray',
      borderWidth: '2px',
      color: 'darkGray',
      transition: '0.3s ease-in-out',
      mb: 2,
      '&:focus': {
        outline: 0,
        borderColor: 'midGray',
        boxShadow: 'imageSmall',
      },
      '&:hover, &:active': {
        boxShadow: 'imageSmall',
      },
      '&::placeholder': {
        color: 'midGray',
      },
    },
    formgroup: {
      mb: 3,
    },
  },
  styles: {
    root: {
      minHeight: 'calc(100% + env(safe-area-inset-top))',
      margin: 0,
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: '62.5%',
      color: 'black',
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
      scrollBehavior: 'smooth',
      button: {
        padding: '0',
        border: 'none',
        background: 'none',
        cursor: 'pointer',
      },
    },
    h1: {
      variant: 'text.heading',
    },
    h2: {
      variant: 'text.heading',
    },
    h3: {
      variant: 'text.headingSm',
    },
    p: {
      variant: 'text.default',
    },
    a: {
      variant: 'text.default',
      color: 'darkPink',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    hr: {
      width: '100%',
      border: 'none',
      height: '1px',
      backgroundColor: '#ececec',
    },
    ol: {
      variant: 'text.default',
    },
    ul: {
      variant: 'text.default',
    },
    td: {
      variant: 'text.default',
    },
    th: {
      variant: 'text.headingSm',
    },
  },
};

export default theme;
