/** @jsx jsx */
import { jsx, Container, Grid, Flex, Box, Text, Spinner, Paragraph, Divider, useThemeUI } from 'theme-ui';
import Button from './Button';
import { Player } from '@lottiefiles/react-lottie-player';
import BagToggle from 'assets/json/bag-toggle.json';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useCart } from '../../context/CartContext';
import { getPrice } from 'components/global/utils/Price';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';

import type { AnimationItem } from 'lottie-web';

type QuantityItemProps = {
  children: React.ReactNode;
  as?: React.ElementType<any>;
  quantity?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const QuantityItem: React.FC<QuantityItemProps> = ({
  children,
  as = 'button',
  quantity = false,
  onClick,
  ...props
}) => (
  <Button
    as={as}
    onClick={onClick}
    variant="noStyle"
    sx={{
      width: '2.5rem',
      height: '2.5rem',
      display: 'flex',
      lineHeight: 0,
      fontSize: '1.5rem',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: as !== 'button' ? 'auto' : 'pointer',
      backgroundColor: quantity ? 'pink' : 'darkGray',
      color: quantity ? 'darkGray' : 'background',
      borderRadius: ' 50%',
      marginRight: 3,
      flexShrink: 0,
      transition: '0.3s ease-in-out',
      '&:hover, &:active': {
        backgroundColor: !quantity ? 'gray' : '',
      },
    }}
    {...props}
  >
    {children}
  </Button>
);

type CartProps = {
  handleToggle: (toggle: boolean) => void;
};

const Cart: React.FC<CartProps> = ({ handleToggle }) => {
  const cartContext = useCart();
  const { theme } = useThemeUI();

  const physicalItems = cartContext.state?.cart.lineItems.physical_items;

  const item = {
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
        staggerChildren: 0.05,
      },
    },
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.05,
      },
    },
  };

  return (
    <Container
      sx={{
        position: 'absolute',
        maxWidth: theme?.breakpoints?.[1],
        left: 0,
        right: 0,
        mx: 'auto',
      }}
    >
      {physicalItems && (
        <motion.div
          initial="hidden"
          animate="visible"
          sx={{
            listStyleType: 'none',
            paddingInlineStart: 0,
          }}
          exit="hidden"
          variants={item}
        >
          {physicalItems.length > 0 ? (
            <Fragment>
              {physicalItems.map((physicalItem) => (
                <motion.div key={physicalItem.id} variants={item}>
                  <Grid
                    columns={['7rem auto auto', null, '10rem auto auto']}
                    gap={[3, null, 4]}
                    sx={{
                      mb: 5,
                    }}
                  >
                    <img
                      sx={{
                        mt: 2,
                        width: '100%',
                        height: ['7rem', null, '10rem'],
                        objectFit: 'cover',
                        boxShadow: 'imageSmall',
                        borderRadius: '0',
                      }}
                      src={physicalItem.image_url}
                      alt={`Produktbild: ${physicalItem.name}`}
                    />
                    <Box>
                      <Text variant="headingSm">{physicalItem.name}</Text>
                      {physicalItem.options &&
                        physicalItem.options.map((option) => (
                          <Text color="darkGray" key={option.name} sx={{ display: 'block' }}>
                            {option.name} : {option.value}
                          </Text>
                        ))}
                      <Flex sx={{ marginTop: 3 }}>
                        <QuantityItem
                          onClick={() =>
                            cartContext.updateCartItemQuantity &&
                            cartContext.updateCartItemQuantity(physicalItem, 'minus')
                          }
                          aria-label="Menge um 1 verringern"
                        >
                          －
                        </QuantityItem>
                        <QuantityItem
                          aria-label={`Ausgewählte Menge: ${physicalItem.quantity}`}
                          quantity={true}
                          as="div"
                        >
                          {physicalItem.quantity}
                        </QuantityItem>
                        <QuantityItem
                          onClick={() =>
                            cartContext.updateCartItemQuantity &&
                            cartContext.updateCartItemQuantity(physicalItem, 'plus')
                          }
                          aria-label="Menge um 1 erhöhen"
                        >
                          ＋
                        </QuantityItem>
                      </Flex>
                    </Box>
                    <Text
                      variant="headingSm"
                      sx={{
                        flexGrow: 1,
                        textAlign: 'right',
                      }}
                    >
                      {getPrice(physicalItem.list_price * physicalItem.quantity)}
                    </Text>
                  </Grid>
                </motion.div>
              ))}
              <motion.div key="spinner" variants={item}>
                {typeof cartContext.state?.addingToCart === 'number' && (
                  <Flex sx={{ justifyContent: 'center', mb: 4 }}>
                    <Spinner color="black" />
                  </Flex>
                )}
              </motion.div>
              <motion.div key="cart-summary" variants={item}>
                <Divider sx={{ mb: 4 }} />
                <Grid columns={'auto auto'} sx={{ mb: 4 }}>
                  <Box>
                    <Text variant="headingMd" sx={{ display: 'block' }}>
                      Gesamt
                    </Text>
                    <Text color="midGray">Inkl. Mwst. und zzgl. Versandkosten</Text>
                  </Box>
                  <Text variant="headingMd" sx={{ textAlign: 'right' }}>
                    {cartContext?.state && getPrice(cartContext.state.cart.cartAmount)}
                  </Text>
                </Grid>
                <Flex
                  sx={{
                    mx: 'auto',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    maxWidth: '22rem',
                    mb: 5,
                  }}
                >
                  <Button as={Link} to="/checkout" sx={{ mb: 4 }}>
                    Jetzt zahlen
                  </Button>
                  <Button onClick={() => handleToggle(false)} variant="secondary">
                    Weiter shoppen
                  </Button>
                </Flex>
              </motion.div>
            </Fragment>
          ) : typeof cartContext.state?.addingToCart === 'number' ? (
            <Flex sx={{ justifyContent: 'center' }}>
              <Spinner color="black" />
            </Flex>
          ) : (
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Paragraph variant="subheading" sx={{ mb: 4 }}>
                Deine Einkaufstasche ist noch leer
              </Paragraph>
              <Button as={Link} to="/shop">
                Jetzt shoppen
              </Button>
            </Flex>
          )}
        </motion.div>
      )}
    </Container>
  );
};

type CartButtonProps = {
  handleClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  toggled: boolean;
};

export const CartButton: React.FC<CartButtonProps> = ({ handleClick, toggled }) => {
  const cartContext = useCart();

  const useQuantity = () => {
    const items = cartContext.state?.cart.lineItems.physical_items || [];
    const total = items.reduce((acc, item) => acc + item.quantity, 0);
    return [total !== 0, total];
  };
  const [hasItems, quantity] = useQuantity();

  const [lottie, setLottie] = useState<AnimationItem>();

  useEffect(() => {
    if (lottie) {
      if (toggled) {
        lottie.playSegments([0, 50], true);
      } else {
        lottie.playSegments([50, 0], true);
      }
    }
  }, [toggled]);

  return (
    <Button
      variant="noStyle"
      onClick={handleClick}
      sx={{
        width: '3rem',
        position: 'relative',
        top: '-0.2rem',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Player lottieRef={(instance) => setLottie(instance)} speed={2} loop={false} keepLastFrame src={BagToggle} />
      {hasItems && (
        <Text
          sx={{
            position: 'absolute',
            bottom: '-0.8rem',
            right: '-0.5rem',
            width: '1.8rem',
            height: '1.8rem',
            display: 'flex',
            lineHeight: 0,
            fontSize: ['1.1rem', '1.1rem', '1.1rem'],
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'black',
            color: 'background',
            borderRadius: '50%',
          }}
        >
          {quantity}
        </Text>
      )}
    </Button>
  );
};

export default Cart;
