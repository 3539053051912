/** @jsx jsx */
import { jsx, Container, Text, Heading, Box, useThemeUI } from 'theme-ui';
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import type { Maybe, ImageSharp } from 'types/graphql';

type CategoryPageHeaderProps = {
  title: Maybe<string> | undefined;
  description: Maybe<string> | undefined;
  gatsbyImage: ImageSharp['gatsbyImageData'];
};

const CategoryPageHeader: React.FC<CategoryPageHeaderProps> = ({ title, description, gatsbyImage }) => {
  const { theme } = useThemeUI();
  return (
    <Box
      sx={{
        paddingTop: ['4rem', null, '6rem'],
        paddingBottom: ['2rem', null, '4rem'],
        position: 'relative',
      }}
    >
      {gatsbyImage && (
        <GatsbyImage
          image={gatsbyImage}
          alt=""
          sx={{
            width: '100%',
            maxWidth: theme.breakpoints?.[4],
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            mx: 'auto',
          }}
        />
      )}
      <Box
        sx={{
          backgroundImage: `linear-gradient(to left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 15%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 85%, rgba(255,255,255,1) 100%);`,
          width: theme.breakpoints?.[4],
          mx: 'auto',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        }}
      />
      <Box
        sx={{
          backgroundImage: `linear-gradient(180deg, rgba(252, 241, 251, 0.26) 0%, rgba(255, 253, 250, 0.92) 70%, rgba(255, 255, 255, 1) 100%)`,
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        }}
      />
      <Container>
        <Box
          sx={{
            position: 'relative',
            maxWidth: ['30rem', '80rem'],
            width: '80%',
          }}
        >
          <Heading as="h1">{title}</Heading>

          <Text variant="subheading" sx={{ position: 'relative' }}>
            {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
          </Text>
        </Box>
      </Container>
    </Box>
  );
};

export default CategoryPageHeader;
