/** @jsx jsx */
import { jsx, Container, Box, Grid, Flex, Heading } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';
import CategoryListItem from 'components/shop/CategoryListItem';

import type { TopLevelCategoriesQuery } from 'types/graphql';

const CategoriesList = () => {
  const {
    allBigCommerceCategories: { nodes: categories },
  } = useStaticQuery<TopLevelCategoriesQuery>(graphql`
    query TopLevelCategories {
      allBigCommerceCategories(filter: { parent_id: { eq: 0 } }) {
        nodes {
          id
          parent_id
          name
          description
          image_sharp {
            childImageSharp {
              gatsbyImageData(width: 270)
            }
          }
          custom_url {
            url
          }
        }
      }
    }
  `);
  return (
    <Container>
      <Grid columns={[2, 3, 4]} gap={[4, null, null, 5]}>
        {categories.map((category) => (
          <Box key={category.id}>
            <CategoryListItem
              title={category.name}
              gatsbyImage={category.image_sharp?.childImageSharp?.gatsbyImageData}
              link={`/shop${category.custom_url?.url}`}
            />
          </Box>
        ))}
      </Grid>
    </Container>
  );
};

export default CategoriesList;
