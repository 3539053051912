/** @jsx jsx */
import { jsx, Box, Card, Themed, Text, Flex } from 'theme-ui';
import { Link } from 'gatsby';
import { getPrice } from 'components/global/utils/Price';
import { GatsbyImage } from 'gatsby-plugin-image';

import type { Maybe, ImageSharp } from 'types/graphql';

type ProductListItemProps = {
  gatsbyImage: ImageSharp['gatsbyImageData'];
  alt: Maybe<string> | undefined;
  title: Maybe<string> | undefined;
  price: Maybe<number> | undefined;
  salePrice: Maybe<number> | undefined;
  link: Maybe<string> | undefined;
};

const ProductListItem: React.FC<ProductListItemProps> = ({ gatsbyImage, alt = '', title, price, salePrice, link }) => {
  return (
    <Link to={link || '/shop'} sx={{ textDecoration: 'none' }}>
      <Card
        sx={{
          borderRadius: 1,
          backgroundColor: 'background',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
          zIndex: 50,
          boxShadow: ' 0px 5px 20px rgba(207, 145, 179, 0.15)',
          transition: '0.4s ease-in-out',
          '&:hover, &:active': {
            boxShadow: ' 0px 5px 20px 15px rgba(207, 145, 179, 0.15)',
          },
        }}
      >
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            pt: '100%',
            overflow: 'hidden',
          }}
        >
          <GatsbyImage
            image={gatsbyImage}
            alt={alt || ''}
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
        <Box sx={{ padding: [3, null, 4] }}>
          <Themed.h3 sx={{ marginBottom: 3 }}>{title}</Themed.h3>
          {salePrice ? (
            <Flex sx={{ flexWrap: 'wrap' }}>
              <Text
                sx={{
                  color: 'darkPink',
                  textDecoration: 'line-through',
                  mr: 3,
                }}
              >
                {price ? getPrice(price) : ''}
              </Text>
              <Text sx={{ color: 'darkGray' }}>{salePrice ? getPrice(salePrice) : ''}</Text>
            </Flex>
          ) : (
            <Text sx={{ color: 'darkGray' }}>{price ? getPrice(price) : ''}</Text>
          )}
        </Box>
      </Card>
    </Link>
  );
};

export default ProductListItem;
