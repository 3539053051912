/** @jsx jsx */
import { jsx, Box, Card, Themed } from 'theme-ui';
import { Link, useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Logo from 'assets/images/logo.png';

import type { Maybe, ImageSharp, LogoQuery } from 'types/graphql';

type CategoryListItemProps = {
  title: Maybe<string> | undefined;
  gatsbyImage: ImageSharp['gatsbyImageData'];
  link: Maybe<string> | undefined;
};

const CategoryListItem: React.FC<CategoryListItemProps> = ({ gatsbyImage, title, link }) => {
  const { file } = useStaticQuery<LogoQuery>(graphql`
    query Logo {
      file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 270)
        }
      }
    }
  `);

  return (
    <Link to={link || '/shop'} sx={{ textDecoration: 'none' }}>
      <Card
        sx={{
          borderRadius: 1,
          backgroundColor: 'background',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
          zIndex: 50,
          boxShadow: ' 0px 5px 20px rgba(207, 145, 179, 0.15)',
          transition: '0.4s ease-in-out',
          '&:hover, &:active': {
            boxShadow: ' 0px 5px 20px 15px rgba(207, 145, 179, 0.15)',
          },
        }}
      >
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            pt: '100%',
          }}
        >
          <GatsbyImage
            image={gatsbyImage || file?.childImageSharp?.gatsbyImageData}
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: '100%',
              objectFit: gatsbyImage ? 'cover' : 'contain',
            }}
            alt={`Anzeigebild für Kategorie ${title}`}
          />
        </Box>
        <Box sx={{ padding: [3, null, null, 4] }}>
          <Themed.h3 sx={{ marginBottom: 3 }}>{title}</Themed.h3>
        </Box>
      </Card>
    </Link>
  );
};

export default CategoryListItem;
