/** @jsx jsx */
import { useRef } from 'react';
import { jsx, get, Theme, Container, Box, Heading, Text, useThemeUI } from 'theme-ui';
import { StaticImage } from 'gatsby-plugin-image';
import { Appear, Parallax } from 'components/global/utils/Animations';

const TextboxImage = () => {
  const { theme } = useThemeUI();

  const sectionRef = useRef<HTMLDivElement>(null);

  return (
    <section
      ref={sectionRef}
      sx={{
        position: 'relative',
        mb: '-12rem', // Negative amount of StaticImage bottom - margin
        py: [null, null, 6],
      }}
    >
      <Container
        sx={{
          maxWidth: theme.breakpoints?.[0],
        }}
      >
        <Appear>
          <Box
            sx={{
              background: (t: Theme) => get(t, 'gradients.primary'),
              width: '80%',
              padding: '2rem 3rem',
              borderRadius: 1,
              textAlign: 'right',
              float: 'right',
              paddingBottom: '20rem',
            }}
          >
            <Appear>
              <Heading>Handgemacht mit viel Liebe zum Detail</Heading>
              <Text variant="subheading" color="midGray">
                Du kannst aus vielen Möglichkeiten und Farbkombinationen dein Wunschprodukt erstellen.
              </Text>
            </Appear>
          </Box>
        </Appear>

        <Appear>
          <Parallax refElement={sectionRef} y={-0.1}>
            <StaticImage
              sx={{
                position: 'relative',
                zIndex: 50,
                bottom: '18rem',
                borderRadius: 1,
                width: '80%',
                maxWidth: '30rem',
                boxShadow: 'image',
              }}
              width={300}
              height={400}
              src="../../assets/images/horse-2.jpg"
              alt="Pferd mit Halfter"
            />
          </Parallax>
        </Appear>
      </Container>
    </section>
  );
};

export default TextboxImage;
