/** @jsx jsx */
import { jsx, Container, Grid, Box } from 'theme-ui';
import ProductListItem from 'components/global/ProductListItem';

import type { SalePageQuery } from 'types/graphql';

type SaleListProps = {
  products: SalePageQuery['allBigCommerceProducts']['nodes'];
};

const SaleList: React.FC<SaleListProps> = ({ products }) => {
  return (
    <Container>
      <Grid columns={[2, 3, 4]} gap={[4, null, null, 5]}>
        {products.map((product) => (
          <Box key={product.id}>
            <ProductListItem
              title={product.name}
              gatsbyImage={
                product.images?.filter((image) => image?.is_thumbnail).pop()?.image_sharp?.childImageSharp
                  ?.gatsbyImageData
              }
              alt={product?.images?.filter((image) => image?.is_thumbnail).pop()?.description}
              price={product.price}
              salePrice={product.sale_price}
              link={product?.custom_url?.url ? `/shop${product.custom_url.url}` : '/shop'}
            />
          </Box>
        ))}
      </Grid>
    </Container>
  );
};

export default SaleList;
