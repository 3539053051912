/** @jsx jsx */
import {
  jsx,
  Container,
  Heading,
  Flex,
  Box,
  Input,
  Text,
  Label,
  Paragraph,
  Divider,
  useThemeUI,
  Grid,
  BaseStyles,
} from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Thumbs, A11y } from 'swiper';
import { useState, useEffect, Fragment } from 'react';
import { Form, Formik, ErrorMessage, useFormikContext } from 'formik';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as Yup from 'yup';
import { useCart } from 'src/context/CartContext';
import Button from 'components/global/Button';
import { getPrice } from 'components/global/utils/Price';

import Bubble from 'assets/svgs/bubble-3.svg';
import ArrowLeft from 'assets/svgs/arrow-left.svg';
import ArrowRight from 'assets/svgs/arrow-right.svg';

import 'swiper/swiper.scss';
import 'swiper/components/thumbs/thumbs.scss';
import 'swiper/components/navigation/navigation.scss';

import type { ProductPageQuery } from 'types/graphql';
import type { FormikProps } from 'formik';
import type { ProductPageContext, OptionValue } from 'types/types';

type FormValues = {
  modifiers: Record<string, string>;
  options: {
    variantId: number;
    optionValues: Record<string, string>;
  };
};

type ProductProps = {
  product: ProductPageQuery['bigCommerceProducts'];
  context: ProductPageContext;
};

const Product: React.FC<ProductProps> = ({ product, context }) => {
  SwiperCore.use([Navigation, Thumbs, A11y]);

  const [mainSwiper, setMainSwiper] = useState<SwiperCore>();
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>();
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const cartContext = useCart();
  const { theme } = useThemeUI();

  const getBreakpointAsNumber = (breakpoint: number) =>
    parseInt(theme.breakpoints?.[breakpoint].replace(/\D+/, '') || '0', 10);

  const modifierValues = context?.modifiers?.reduce((acc, curr) => ({ ...acc, [curr.display_name]: '' }), {});

  const options = product?.variants?.[0]?.option_values?.reduce(
    (acc, curr, i) => ({
      ...acc,
      [curr?.option_display_name || `variant-${i}`]: product?.variants?.[0]?.option_values?.[i]?.label,
    }),
    {},
  );

  const initialValues = {
    modifiers: modifierValues,
    options: {
      variantId: 0,
      optionValues: options,
    },
  } as FormValues;

  const validationSchema = Yup.object().shape({
    modifiers: Yup.object().shape(
      Object.keys(initialValues.modifiers).reduce(
        (acc, curr) => ({ ...acc, [curr]: Yup.string().required(`Bitte ${curr} angeben`) }),
        {},
      ),
    ),
    options: Yup.object().shape({
      variantId: Yup.number().required('Bitte wähle eine Variante aus'),
      optionValues: Yup.object().shape(
        Object.keys(initialValues.options.optionValues).reduce(
          (acc, curr) => ({ ...acc, [curr]: Yup.string().required(`Bitte ${curr} angeben`) }),
          {},
        ),
      ),
    }),
  });

  const getPriceInfos = (variantId: number) => {
    if (product?.variants) {
      const variant = product.variants.find((variant) => variant?.id === variantId);

      return {
        salePrice: variant?.sale_price || product.sale_price || 0,
        price: variant?.price || product.price || 0,
        isOnSale: variant?.sale_price ? true : product.sale_price ? true : false,
      };
    } else {
      return {
        salePrice: 0,
        price: 0,
        isOnSale: false,
      };
    }
  };

  const keyDown = (event: KeyboardEvent) => {
    // Arrow right key
    if (event.key === 'ArrowRight' || event.key === 'Right') {
      mainSwiper?.slideNext();
    }

    // Arrow left key
    if (event.key === 'ArrowLeft' || event.key === 'Left') {
      mainSwiper?.slidePrev();
    }
  };

  // Check for pressed keys
  useEffect(() => {
    document.addEventListener('keydown', keyDown, false);
    return () => {
      document.removeEventListener('keydown', keyDown, false);
    };
  });

  return (
    <Fragment>
      <Container
        sx={{
          paddingTop: ['4rem', null, '6rem'],
        }}
      >
        <Heading as="h1">{product?.name}</Heading>
        <Grid
          columns={[1, 1, 2]}
          gap={[4, null, 5, 6]}
          sx={{
            justifyItems: [null, 'center', 'stretch'],
          }}
        >
          <Box
            sx={{
              position: 'relative',
              maxWidth: theme.breakpoints?.[0],
              pt: 4,
              pb: 5,
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: -3,
                right: [2, null, -1],
                left: [2, null, -1],
              }}
            >
              <Bubble />
            </Box>

            <Flex
              sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                px: 4,

                maxWidth: theme.breakpoints?.[0],
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  position: 'relative',
                }}
              >
                <Swiper
                  onSwiper={setMainSwiper}
                  sx={{
                    width: '100%',
                    borderRadius: 1,
                    overflow: 'hidden',
                    boxShadow: 'image',
                  }}
                  onSlideChange={(swiper) => {
                    setIsBeginning(swiper.isBeginning);
                    setIsEnd(swiper.isEnd);
                    setActiveIndex(swiper.activeIndex);
                  }}
                  thumbs={{ swiper: thumbsSwiper }}
                >
                  {product?.images?.map((image, index) => (
                    <SwiperSlide key={'slide-' + index}>
                      <Box
                        sx={{
                          width: '100%',
                          pt: '100%',
                          position: 'relative',
                        }}
                      >
                        <GatsbyImage
                          image={image?.image_sharp?.childImageSharp?.gatsbyImageData}
                          alt={image?.description || ''}
                          sx={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center',
                          }}
                        />
                      </Box>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <SwiperButton type="previous" swiper={mainSwiper} isBeginning={isBeginning}>
                  <ArrowLeft />
                </SwiperButton>
                <SwiperButton type="next" swiper={mainSwiper} isEnd={isEnd}>
                  <ArrowRight />
                </SwiperButton>
              </Box>

              <Swiper
                onSwiper={setThumbsSwiper}
                watchSlidesVisibility
                watchSlidesProgress
                breakpoints={{
                  0: { slidesPerView: 3 },
                  340: { slidesPerView: 4 },
                  420: { slidesPerView: 5 },
                  480: { slidesPerView: 6 },
                  540: { slidesPerView: 7 },
                  [getBreakpointAsNumber(1)]: { slidesPerView: 4 },
                  [getBreakpointAsNumber(1) + 60]: { slidesPerView: 5 },
                  [getBreakpointAsNumber(2) + 60]: { slidesPerView: 6 },
                }}
                spaceBetween={10}
                sx={{
                  width: '100%',
                  py: 3,
                }}
              >
                {product?.images?.map((image, index) => (
                  <SwiperSlide key={'thumb-' + index}>
                    <button
                      tabIndex={-1}
                      onClick={() => mainSwiper?.slideTo(index)}
                      sx={{
                        width: '6rem',
                        height: '6rem',
                        borderRadius: 0,
                        overflow: 'hidden',
                        boxShadow: '0px 2px 7px rgb(137 71 118 / 11%)',
                        position: 'relative',
                        zIndex: 50,
                      }}
                    >
                      <GatsbyImage
                        image={image?.image_sharp?.childImageSharp?.gatsbyImageData}
                        alt={image?.description || ''}
                        sx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: 'black',
                          opacity: activeIndex === index ? 0.2 : 0,
                          transition: '0.2s ease-in-out',
                          '&:hover, &:active': {
                            opacity: 0.2,
                          },
                        }}
                      />
                    </button>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Flex>
          </Box>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const modifiers = Object.entries(values.modifiers).reduce((acc, [key, value]) => {
                const option = context.modifiers?.find((modifier) => modifier.display_name === key);

                // If selected product has modifier options add them to the request
                if (option?.type === 'text') {
                  /* @ts-ignore:disable-next-line */
                  // TODO: Fix type
                  return [...acc, { option_id: option.id, option_value: value }];
                } else if (option?.type === 'dropdown' || option?.type === 'swatch') {
                  return [
                    ...acc,
                    {
                      option_id: option.id,
                      option_value: option.option_values
                        .find((optionValue) => optionValue.label === value)
                        ?.id.toString(),
                    },
                  ];
                }
              }, [] as { option_id: number; option_value: string }[] | undefined);

              if (cartContext.addToCart && product?.bigcommerce_id) {
                cartContext.addToCart(product?.bigcommerce_id, values.options.variantId, modifiers);
              }
            }}
          >
            {({ values, errors, touched, setFieldValue, handleChange, handleBlur }: FormikProps<FormValues>) => (
              <Form
                sx={{
                  pt: [null, null, 4],
                }}
              >
                {/* Modifiers */}
                {context?.modifiers && (
                  <Flex sx={{ flexDirection: 'column' }}>
                    {context.modifiers?.map((modifier) => {
                      if (modifier.type === 'text') {
                        return (
                          <Box variant="forms.formgroup" key={modifier.id}>
                            <Label htmlFor={modifier.id.toString()}>{modifier.display_name}</Label>
                            <Input
                              id={modifier.id.toString()}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.modifiers[modifier.display_name]}
                              name={`modifiers.${modifier.display_name}`}
                              placeholder={modifier.display_name}
                              sx={{
                                maxWidth: '40rem',
                              }}
                            />
                            <ErrorMessage name={`modifiers.${modifier.display_name}`}>
                              {(msg) => <Text variant="error">{msg}</Text>}
                            </ErrorMessage>
                          </Box>
                        );
                      } else {
                        return (
                          <Box variant="forms.formgroup" key={modifier.id}>
                            <fieldset
                              sx={{
                                variant: 'forms.fieldset',
                              }}
                            >
                              <legend
                                sx={{
                                  variant: 'forms.label',
                                }}
                              >
                                {modifier.display_name}
                              </legend>
                              {modifier.option_values.map((value) => (
                                <SelectButton
                                  key={value.id}
                                  handleClick={() => setFieldValue(`modifiers.${modifier.display_name}`, value.label)}
                                  label={value.label}
                                  indicator={value.value_data}
                                  selected={values.modifiers[modifier.display_name] === value.label}
                                />
                              ))}
                            </fieldset>
                            <ErrorMessage name={`modifiers.${modifier.display_name}`}>
                              {(msg) => <Text variant="error">{msg}</Text>}
                            </ErrorMessage>
                          </Box>
                        );
                      }
                    })}
                  </Flex>
                )}
                {/* Options */}
                {context?.options &&
                  context.options.map((option) => (
                    <Box variant="forms.formgroup" key={option.id}>
                      <fieldset sx={{ variant: 'forms.fieldset' }}>
                        <legend sx={{ variant: 'forms.label' }}>{option.display_name}</legend>

                        <Flex sx={{ flexWrap: 'wrap' }}>
                          {option.option_values.map((optionValue) => (
                            <SelectButton
                              key={optionValue.id}
                              handleClick={() =>
                                setFieldValue(`options.optionValues.${option.display_name}`, optionValue.label)
                              }
                              indicator={optionValue.value_data}
                              label={optionValue.label}
                              selected={values.options.optionValues[option.display_name] === optionValue.label}
                            />
                          ))}
                        </Flex>
                      </fieldset>
                      <ErrorMessage name={`options.optionValues.${option.display_name}`}>
                        {(msg) => <Text variant="error">{msg}</Text>}
                      </ErrorMessage>
                    </Box>
                  ))}

                <Flex sx={{ mt: 4 }}>
                  {getPriceInfos(values.options.variantId)?.isOnSale && (
                    <Text
                      variant="headingMd"
                      sx={{
                        textDecoration: 'line-through',
                        color: 'darkPink',
                        mr: 3,
                      }}
                      mb={[1, 1, 2]}
                    >
                      {getPrice(getPriceInfos(values.options.variantId)?.price)}
                    </Text>
                  )}

                  <Text variant="headingMd" mb={4}>
                    {getPrice(
                      getPriceInfos(values.options.variantId)?.isOnSale
                        ? getPriceInfos(values.options.variantId)?.salePrice
                        : getPriceInfos(values.options.variantId)?.price,
                    )}
                  </Text>
                </Flex>

                {Object.keys(errors).length > 0 && Object.keys(touched).length > 0 && (
                  <Text
                    variant="error"
                    sx={{
                      display: 'block',
                      mb: 4,
                    }}
                  >
                    Bitte korrigiere oder vervollständige deine Angaben
                  </Text>
                )}
                <Flex sx={{ justifyContent: ['center', null, 'flex-start'] }}>
                  <Button type="submit">In den Warenkorb</Button>
                </Flex>
                <ValueChangeListener product={product} />
              </Form>
            )}
          </Formik>
        </Grid>
        <Divider
          sx={{
            mt: 5,
            mb: 5,
          }}
        />
        <Heading variant="headingMd">Produktbeschreibung</Heading>
        <BaseStyles>
          {product?.description && <div dangerouslySetInnerHTML={{ __html: product.description }} />}
        </BaseStyles>
      </Container>
    </Fragment>
  );
};

type ValueChangeListenerProps = {
  product: ProductPageQuery['bigCommerceProducts'];
};

const ValueChangeListener: React.FC<ValueChangeListenerProps> = ({ product }) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const findVariant = () => {
    let options = values.options.optionValues;
    let variants = [] as number[];

    // Populate variants array with indexes of variants from every option
    if (product?.variants) {
      for (let index = 0; index <= Object.keys(options).length - 1; index++) {
        product.variants.map((variant, i) => {
          if (variant?.option_values?.[index]?.label === Object.values(options)[index]) {
            variants.push(i);
          }
        });
      }
    }

    // Get most duplicate index from variants array to get variant
    let counts = {} as Record<number, number>;
    let max = 0;
    let res = 0;
    for (let v in variants) {
      counts[variants[v]] = (counts[variants[v]] || 0) + 1;
      if (counts[variants[v]] > max) {
        max = counts[variants[v]];
        res = variants[v];
      }
    }

    const variantId = product?.variants?.[res]?.id;
    return variantId;
  };

  useEffect(() => {
    const variantId = findVariant();
    setFieldValue('options.variantId', variantId);
  }, [values.options.optionValues]);

  return null;
};

type SelectButtonProps = {
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
  label: string;
  selected: boolean;
  indicator: OptionValue['value_data'];
};

const SelectButton: React.FC<SelectButtonProps> = ({ handleClick, label, selected, indicator }) => {
  const getColors = () => {
    if (indicator?.colors) {
      switch (indicator.colors.length) {
        case 2:
          return `linear-gradient(90deg, ${indicator.colors[0]} 0%, ${indicator.colors[0]} 50%, ${indicator.colors[1]} 50%, ${indicator.colors[1]} 100%)`;

        case 3:
          return `linear-gradient(90deg, ${indicator.colors[0]} 0%, ${indicator.colors[0]} 33%, ${indicator.colors[1]} 33%, ${indicator.colors[1]} 66%, ${indicator.colors[2]} 66%, ${indicator.colors[2]} 100%)`;

        default:
          return `linear-gradient(90deg, ${indicator.colors[0]} 0%, ${indicator.colors[0]} 100%)`;
      }
    }
  };

  return (
    <Button
      type="button"
      onClick={handleClick}
      variant="select"
      sx={{
        borderColor: selected ? 'midGray' : 'lightGray',
        boxShadow: selected ? 'imageSmall' : '',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mb: 2,
        mr: 2,
        transition: '0.3s ease-in-out',
        '&:hover, &:active': {
          boxShadow: 'imageSmall',
        },
      }}
    >
      {indicator && (
        <Box
          sx={{
            mr: '0.8rem',
            borderRadius: '50%',
            width: '1.9rem',
            height: '1.9rem',
            background: indicator?.colors ? getColors() : 'initial',
            overflow: 'hidden',
            lineHeight: 0,
          }}
        >
          {indicator.image_url && (
            <img
              src={indicator.image_url}
              alt=""
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          )}
        </Box>
      )}
      <Text>{label}</Text>
    </Button>
  );
};

type SwiperButtonProps = {
  type: 'previous' | 'next';
  swiper: SwiperCore | undefined;
  isBeginning?: boolean;
  isEnd?: boolean;
};

const SwiperButton: React.FC<SwiperButtonProps> = ({ type, swiper, children }) => (
  <button
    onClick={() => {
      type === 'previous' ? swiper?.slidePrev() : swiper?.slideNext();
    }}
    sx={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: type === 'previous' ? '-2.4rem' : '',
      right: type === 'next' ? '-2.4rem' : '',
      my: 'auto',
      padding: 0,
      backgroundColor: '#F4F4F4',
      width: '4.8rem',
      height: '4.8rem',
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.18)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      zIndex: 20,
      svg: {
        opacity: (type === 'previous' && swiper?.isBeginning) || (type === 'next' && swiper?.isEnd) ? 0.3 : 1,
        transition: '0.2s ease-in-out',
      },
    }}
    aria-label={type === 'previous' ? 'Vorheriges Bild' : 'Nächstes Bild'}
    disabled={(type === 'previous' && swiper?.isBeginning) || (type === 'next' && swiper?.isEnd)}
  >
    {children}
  </button>
);

export default Product;
