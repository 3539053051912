/** @jsx jsx */
import { jsx, Container, Flex, Text, Divider } from 'theme-ui';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql, useStaticQuery } from 'gatsby';
import IconInstagram from 'assets/svgs/instagram-icon.svg';

import type { LegalLinksQuery } from 'types/graphql';

const Footer = () => {
  const {
    allContentfulLegal: { nodes: legalLinks },
  } = useStaticQuery<LegalLinksQuery>(graphql`
    query LegalLinks {
      allContentfulLegal(sort: { fields: slug, order: ASC }) {
        nodes {
          id
          slug
          title
        }
      }
    }
  `);

  return (
    <footer
      sx={{
        pt: 6,
        pb: 5,
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Divider sx={{ mb: 5 }} />
        <Text variant="headingSm" sx={{ mb: 2 }}>
          Finde uns auf Instagram
        </Text>
        <a aria-label="Instagram" href="https://www.instagram.com/equidreamsshop">
          <IconInstagram sx={{ mb: 4 }} />
        </a>
        <Flex
          sx={{
            alignItems: 'center',
            mb: 4,
          }}
        >
          <StaticImage sx={{ width: '5rem', mr: 3 }} src="../../assets/images/logo.png" alt="Logo" />
          <Text variant="heading" color="darkGray" sx={{ mb: [0, 0] }}>
            Equi Dreams
          </Text>
        </Flex>
        <Flex
          sx={{
            justifyContent: 'center',
            mb: 4,
            flexWrap: 'wrap',
          }}
        >
          {legalLinks.map((legalLink) => (
            <Link
              key={legalLink.id}
              sx={{
                variant: 'styles.a',
                color: 'darkGray',
                mx: 2,
              }}
              to={`/${legalLink.slug}`}
            >
              {legalLink.title}
              <Text variant="legal"></Text>
            </Link>
          ))}
        </Flex>
        <Text variant="legal">© {new Date().getFullYear()} Equi Dreams</Text>
      </Container>
    </footer>
  );
};

export default Footer;
