import React, { createContext, useState, useEffect, useContext } from 'react';

// Not complete
type CartContextProps = {
  state: {
    addToCartError?: string;
    addingToCart?: number | boolean;
    addedToCart?: number;
    updatingItem?: number | boolean;
    cartLoading: boolean;
    cartError: boolean;
    cart: {
      currency: {
        code: string;
      };
      cartAmount: number;
      lineItems: {
        physical_items: {
          id: string;
          name: string;
          quantity: number;
          list_price: number;
          image_url: string;
          options: { name: string; nameId: number; value: string; valueId: number }[];
        }[];
      };
      numberItems: number;
      redirectUrls: {
        cart_url: string;
        checkout_url: string;
        embedded_checkout_url: string;
      };
    };
  };
  addToCart: (
    productId: number,
    variantId: number,
    options?: {
      option_id: number;
      option_value: string;
    }[],
    retry?: boolean,
  ) => void;
  removeItemFromCart: (itemId: number) => void;
  updateCartItemQuantity: (item: Record<string, any>, action: 'minus' | 'plus') => void;
  notifications: { text: string; type: string; id: number }[];
  addNotification: (text: string, type?: string) => void;
  removeNotification: any;
};

const CartContext = createContext<Partial<CartContextProps>>({});
CartContext.displayName = 'CartContext';

const initialState = {
  cartLoading: true,
  cartError: false,
  cart: {
    currency: {
      code: 'USD',
    },
    cartAmount: 0,
    lineItems: { physical_items: [] },
    numberItems: 0,
    redirectUrls: {
      cart_url: '',
      checkout_url: '',
      embedded_checkout_url: '',
    },
  },
};

const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState<CartContextProps['state']>(initialState);

  const [notifications, updateNotifications] = useState<CartContextProps['notifications']>([]);

  const addNotification: CartContextProps['addNotification'] = (text, type = 'notify') => {
    updateNotifications([...notifications, { text, type, id: Date.now() }]);
  };

  const removeNotification = (id: number) => {
    updateNotifications(notifications.filter((ntfy) => ntfy.id !== id));
  };

  const fetchCart = () => {
    fetch(`/.netlify/functions/bigcommerce?endpoint=carts`, {
      credentials: 'same-origin',
      mode: 'same-origin',
    })
      .then((res) => res.json())
      .then((response) => {
        refreshCart(response);
      })
      .catch((error) => {
        setState({ ...state, cartLoading: false, cartError: error });
      });
  };

  // eslint-disable-next-line
  useEffect(() => fetchCart(), []);

  const refreshCart: (response: Record<string, any>) => void = (response) => {
    if (response.status === 204 || response.status === 404) {
      setState({ ...state, cartLoading: false });
    } else {
      const lineItems = response.data.line_items;
      const cartAmount = response.data.cart_amount;
      const currency = response.data.currency;

      setState({
        ...state,
        cartLoading: false,
        updatingItem: false,
        cart: {
          currency,
          cartAmount,
          lineItems,
          numberItems:
            lineItems.physical_items.length +
            lineItems.digital_items.length +
            lineItems.custom_items.length +
            lineItems.gift_certificates.length,
          redirectUrls: response.data.redirect_urls,
        },
      });
    }
  };

  const addToCart: CartContextProps['addToCart'] = (productId, variantId, options, retry = false) => {
    setState({ ...state, addingToCart: productId });
    fetch(`/.netlify/functions/bigcommerce?endpoint=carts/items`, {
      method: 'POST',
      credentials: 'same-origin',
      mode: 'same-origin',
      body: JSON.stringify({
        // channel_id is inserted here and not in netlify function because contextualized environment variables are not working in netlify functions
        channel_id: process.env.GATSBY_BIGCOMMERCE_CHANNEL_ID,
        line_items: [
          {
            quantity: 1,
            product_id: productId,
            variant_id: variantId,
            option_selections: options,
          },
        ],
      }),
    })
      .then(async (res) => ({ response: await res.json(), status: res.status }))
      .then(({ response, status }) => {
        if (status === 404 && !retry) {
          // re create a cart if cart was destroyed
          return fetch(`/.netlify/functions/bigcommerce?endpoint=carts`, {
            credentials: 'same-origin',
            mode: 'same-origin',
          }).then(() => addToCart(productId, variantId, options, true));
        }
        status < 300 && addNotification('Item added successfully');

        const lineItems = response.data.line_items;
        const cartAmount = response.data.cart_amount;
        const currency = response.data.currency;

        setState({
          ...state,
          addingToCart: false,
          addedToCart: productId,
          cart: {
            currency,
            cartAmount,
            lineItems,
            numberItems:
              lineItems.physical_items.length +
              lineItems.digital_items.length +
              lineItems.custom_items.length +
              lineItems.gift_certificates.length,
            redirectUrls: response.data.redirect_urls,
          },
        });
      })
      .catch((error) => {
        setState({ ...state, addingToCart: false, addToCartError: error });
      });
  };

  const updateItemInCart: (itemId: number, updatedItemData: Record<string, any>) => void = (
    itemId,
    updatedItemData,
  ) => {
    fetch(`/.netlify/functions/bigcommerce?endpoint=carts/items&itemId=${itemId}`, {
      credentials: 'same-origin',
      mode: 'same-origin',
      method: 'put',
      body: JSON.stringify(updatedItemData),
    })
      .then((res) => res.json())
      .then((response) => {
        refreshCart(response);
      })
      .catch((error) => {
        setState({ ...state, cartLoading: false, cartError: error });
      });
  };

  const removeItemFromCart: CartContextProps['removeItemFromCart'] = (itemId) => {
    fetch(`/.netlify/functions/bigcommerce?endpoint=carts/items&itemId=${itemId}`, {
      credentials: 'same-origin',
      mode: 'same-origin',
      method: 'delete',
    })
      .then((res) => {
        // addNotification('Item removed successfully');
        if (res.status === 204) {
          setState(initialState);
          return;
        }
        // addNotification('Item removed successfully');
        return res.json();
      })
      .then((response) => {
        response && refreshCart(response);
      })
      .catch((error) => {
        setState({ ...state, cartLoading: false, cartError: error });
      });
  };

  const updateCartItemQuantity: CartContextProps['updateCartItemQuantity'] = (item, action) => {
    const newQuantity = item.quantity + (action === 'minus' ? -1 : 1);
    setState({ ...state, updatingItem: item.id });
    if (newQuantity < 1) {
      return removeItemFromCart(item.id);
    }
    let productVariantReferences = null;

    if (typeof item.product_id !== 'undefined') {
      productVariantReferences = {
        product_id: item.product_id,
        variant_id: item.variant_id,
      };
    }

    updateItemInCart(item.id, {
      line_item: {
        quantity: newQuantity,
        ...productVariantReferences,
      },
    });
  };

  return (
    <CartContext.Provider
      value={{
        state,
        addToCart,
        removeItemFromCart,
        updateCartItemQuantity,
        notifications,
        addNotification,
        removeNotification,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

export { CartProvider, useCart };
