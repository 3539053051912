/** @jsx jsx */
import { jsx, Container, Grid, Flex, Heading, Themed } from 'theme-ui';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Button from 'components/global/Button';
import VisaIcon from 'assets/svgs/visa-icon.svg';
import MastercardIcon from 'assets/svgs/mastercard-icon.svg';
import MaestroIcon from 'assets/svgs/maestro-icon.svg';
import PaypalIcon from 'assets/svgs/paypal-icon.svg';
import SEPAIcon from 'assets/svgs/sepa-icon.svg';
import DHLLogo from 'assets/svgs/dhl-logo.svg';
import ChatIcon from 'assets/svgs/chat-icon.svg';
import React from 'react';
import { Appear } from 'components/global/utils/Animations';
import { getPrice } from 'components/global/utils/Price';

import type { CheapestProductQuery } from 'types/graphql';

const GridItem: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Appear>
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 4,
        '>:nth-of-type(1)': {
          height: '4rem',
        },
      }}
    >
      {children}
    </Flex>
  </Appear>
);

const H3: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Themed.h3 sx={{ marginTop: 3 }}>{children}</Themed.h3>
);

const OrderInfo = () => {
  const {
    allBigCommerceProducts: { nodes: cheapestProduct },
  } = useStaticQuery<CheapestProductQuery>(graphql`
    query CheapestProduct {
      allBigCommerceProducts(sort: { fields: price, order: ASC }, limit: 1) {
        nodes {
          price
        }
      }
    }
  `);

  return (
    <section>
      <Container>
        <Appear>
          <Heading sx={{ textAlign: 'center' }}>
            Dein persönliches Stück schon ab {getPrice(cheapestProduct[0].price || 10)}
          </Heading>
        </Appear>
        <Grid
          columns={[1, 1, 'auto auto auto', 3]}
          gap={4}
          sx={{
            mb: 4,
          }}
        >
          <GridItem>
            <Flex
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                svg: {
                  marginRight: 1,
                  marginLeft: 1,
                },
              }}
            >
              <VisaIcon alt="Visa Logo" />
              <MastercardIcon alt="Mastercard Logo" />
              <MaestroIcon alt="Maestro Logo" />
              <PaypalIcon alt="Paypal Logo" />
              <SEPAIcon alt="SEPA Logo" />
            </Flex>
            <H3>Alle Zahlungsmethoden</H3>
          </GridItem>
          <GridItem>
            <DHLLogo alt="DHL Logo" />

            <H3>Versicherter Versand mit DHL</H3>
          </GridItem>
          <GridItem>
            <ChatIcon alt="" />

            <H3>Kostenlose persönliche Beratung</H3>
          </GridItem>
        </Grid>
        <Flex sx={{ justifyContent: 'center' }}>
          <Appear>
            <Button as={Link} to="/shop">
              Zum Shop
            </Button>
          </Appear>
        </Flex>
      </Container>
    </section>
  );
};

export default OrderInfo;
