import React, { useState, useEffect, RefObject } from 'react';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import { InView } from 'react-intersection-observer';

import type { Variants, MotionValue } from 'framer-motion';

export const itemVariant: Variants = {
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
      when: 'afterChildren',
      staggerDirection: -1,
      staggerChildren: 0.2,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
      when: 'beforeChildren',
      staggerChildren: 0.2,
    },
  },
};

type AppearProps = {
  children: React.ReactNode;
};

export const Appear: React.FC<AppearProps> = ({ children }) => (
  <InView threshold={0.2}>
    {({ ref, inView }) => (
      <motion.div ref={ref} initial="hidden" animate={inView && 'visible'} variants={itemVariant}>
        {React.Children.map(children, (child, index) => (
          <motion.div style={{ height: '100%' }} key={index} variants={itemVariant}>
            {child}
          </motion.div>
        ))}
      </motion.div>
    )}
  </InView>
);

type UseParallax = (
  refElement: RefObject<HTMLElement>,
  yTransform: number,
  xTransform: number,
) => { y: MotionValue<number>; x: MotionValue<number> };

export const useParallax: UseParallax = (refElement, yTransform, xTransform) => {
  const [height, setHeight] = useState(0);

  const { scrollY } = useViewportScroll();
  const y = useTransform(scrollY, [height, height + 1], [0, yTransform], { clamp: false });
  const x = useTransform(scrollY, [height, height + 1], [0, xTransform], { clamp: false });

  useEffect(() => {
    if (refElement.current) {
      setHeight(refElement.current.offsetTop);
    }
  }, [refElement]);

  return { y, x };
};

type ParallaxProps = {
  refElement: RefObject<HTMLElement>;
  y: number;
  x?: number;
};

export const Parallax: React.FC<ParallaxProps> = ({ refElement, y: yTransform, x: xTransform, children }) => {
  const { y, x } = useParallax(refElement, yTransform, xTransform || 0);

  return <motion.div style={{ y, x }}>{children}</motion.div>;
};
