import React from 'react';
import PageHeader from 'components/global/PageHeader';
import CategoriesList from 'components/shop/CategoriesList';
import SEO from 'components/global/Seo';
import IconCart from 'assets/svgs/cart.svg';

const ShopPage = () => {
  return (
    <>
      <SEO
        title="Shop"
        description="Du kannst unter vielen verschiedenen Kategorien dein Wunschprodukt finden und zusammenstellen"
      />
      <PageHeader
        title="Shop"
        text="Du kannst unter vielen verschiedenen Kategorien dein Wunschprodukt finden und zusammenstellen"
        icon={<IconCart />}
      />
      <CategoriesList />
    </>
  );
};

export default ShopPage;
