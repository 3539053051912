/** @jsx jsx */
import { useRef, useState, useEffect, Fragment } from 'react';
import { jsx, Container, Box, Heading, useThemeUI } from 'theme-ui';
import { StaticImage } from 'gatsby-plugin-image';
import { Appear, Parallax } from 'components/global/utils/Animations';
import useWindowDimensions from 'components/global/utils/useWindowDimensions';
import Bubble from 'assets/svgs/bubble-2.svg';

const Clients = () => {
  const { windowWidth } = useWindowDimensions();
  const offsetCalc = windowWidth && (windowWidth - 320) / 2 / 10;
  const maxOffset = 22.5;
  const offset = offsetCalc && offsetCalc < maxOffset ? offsetCalc : maxOffset;

  const { theme } = useThemeUI();

  const [mounted, setMounted] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <section ref={sectionRef}>
      <Container
        sx={{
          position: 'relative',
          maxWidth: theme.breakpoints?.[1],
          px: [4, 4, 4, 4],
        }}
      >
        <Appear>
          <Parallax refElement={sectionRef} y={0.1}>
            <Bubble
              sx={{
                position: 'absolute',
                margin: '0 auto',
                top: ['15rem', null, '20rem'],
                left: 0,
                right: 0,
                width: ['98%', '100%'],
              }}
              alt=""
            />
          </Parallax>
          <Heading sx={{ position: 'relative', textAlign: 'center', mb: [6, null] }}>
            Tausend zufriedene Kunden. Und Pferde.
          </Heading>
        </Appear>

        <Box
          sx={{
            height: `calc(45rem + ${offset} * 3rem)`,
            position: 'relative',
            '.image': {
              borderRadius: 1,
              boxShadow: 'image',
              position: 'absolute',
              width: '50%',
              zIndex: 50,

              '&.image-2': {
                top: '6rem',
                left: 'auto',
                right: 0,
                width: '60%',
              },
              '&.image-3': {
                top: [`calc(17.5rem + ${offset}rem)`, `calc(17rem + ${offset}rem)`],
                left: '3rem',
                width: '43%',
              },
              '&.image-4': {
                top: [`calc(14rem + ${offset}rem)`, `calc(12rem + ${offset}rem)`],
                right: ['2.5rem', '4.5rem'],
                width: '47%',
              },
              '&.image-5': {
                top: `calc(32rem + ${offset} * 2rem)`,
                left: '8rem',
              },
            },
          }}
        >
          <Appear>
            {mounted && (
              <Fragment>
                <Parallax refElement={sectionRef} y={-0.1}>
                  <StaticImage
                    className="image"
                    src="../../assets/images/client-2.jpg"
                    alt="Mädchen mit Pferd im Stall"
                  />
                </Parallax>

                <Parallax refElement={sectionRef} y={-0.12}>
                  <StaticImage
                    className="image image-2"
                    src="../../assets/images/client-1.jpg"
                    alt="Mädchen mit Pferd auf Wiese"
                  />
                </Parallax>

                <Parallax refElement={sectionRef} y={-0.14}>
                  <StaticImage
                    className="image image-3"
                    src="../../assets/images/client-5.jpg"
                    alt="Mädchen mit Blütenhaarreif und Pferd mit Blütenhalfter"
                  />
                </Parallax>

                <Parallax refElement={sectionRef} y={-0.16}>
                  <StaticImage
                    className="image image-4"
                    src="../../assets/images/client-4.jpg"
                    alt="Pferd mit Blütenhalfter"
                  />
                </Parallax>

                <Parallax refElement={sectionRef} y={-0.18}>
                  <StaticImage
                    className="image image-5"
                    src="../../assets/images/client-3.jpg"
                    alt="Mädchen mit Pferd am Meer"
                  />
                </Parallax>
              </Fragment>
            )}
          </Appear>
        </Box>
      </Container>
    </section>
  );
};

export default Clients;
