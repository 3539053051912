import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

type SEOProps = {
  title: string;
  description?: string;
  image?: string;
  robots?: 'noindex';
  pathname?: string;
  type?: string;
};

import type { SeoQuery } from 'types/graphql';

const SEO: React.FC<SEOProps> = ({ title, description, image, robots, pathname, type }) => {
  const { site, file } = useStaticQuery<SeoQuery>(
    graphql`
      query Seo {
        site {
          siteMetadata {
            defaultTitle: title
            siteUrl
            lang
          }
        }
        file(relativePath: { eq: "images/logo.png" }) {
          publicURL
        }
      }
    `,
  );

  const lang = site?.siteMetadata?.lang || '';
  const siteUrl = site?.siteMetadata?.siteUrl || '';
  const defaultTitle = site?.siteMetadata?.defaultTitle || '';
  const defaultImage = file?.publicURL || '';

  // Remove leading double slashes (//) from contentful source link received by graphql query
  let modifiedImageUrl;
  if (image) {
    // if external image
    if (image.startsWith('https://')) {
      modifiedImageUrl = image;
    }
    // if static asset
    else {
      modifiedImageUrl = `${siteUrl}${image}`;
    }
  }

  const seo = {
    title: title || defaultTitle,
    description: description || '',
    image: modifiedImageUrl || `${siteUrl}${defaultImage}`,
    siteUrl: pathname ? siteUrl + pathname : siteUrl,
    robots: robots || 'all',
    type: type || 'website',
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${defaultTitle}`}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      <meta property="og:url" content={seo.siteUrl} />
      <meta property="og:type" content={seo.type} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />

      {seo.robots && <meta name="robots" content={seo.robots} />}
    </Helmet>
  );
};

export default SEO;
