import React from 'react';
import { graphql } from 'gatsby';
import SEO from 'components/global/Seo';
import Product from 'components/product/Product';

import type { ProductPageQuery } from 'types/graphql';
import type { PageProps } from 'gatsby';
import type { ProductPageContext } from 'types/types';

const ProductPage: React.FC<PageProps<ProductPageQuery, ProductPageContext>> = ({ data, pageContext }) => {
  return (
    <>
      <SEO
        title={data.bigCommerceProducts?.name || ''}
        description={data.bigCommerceProducts?.meta_description || ''}
        image={data.bigCommerceProducts?.images?.filter((image) => image?.is_thumbnail)?.pop()?.url_standard || ''}
        type="product"
        pathname={data.bigCommerceProducts?.custom_url?.url || ''}
      />
      <Product product={data.bigCommerceProducts} context={pageContext} />
    </>
  );
};

export const query = graphql`
  query ProductPage($id: Int!) {
    bigCommerceProducts(bigcommerce_id: { eq: $id }) {
      name
      description
      meta_description
      bigcommerce_id
      price
      sale_price
      custom_url {
        url
      }
      variants {
        id
        option_values {
          option_display_name
          option_id
          id
          label
        }
        image_url
        price
        sale_price
        sku_id
      }
      images {
        url_standard
        description
        is_thumbnail
        image_sharp {
          childImageSharp {
            gatsbyImageData(width: 550, quality: 80)
          }
        }
      }
    }
  }
`;

export default ProductPage;
