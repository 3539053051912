/** @jsx jsx */
import { jsx, useThemeUI, get } from 'theme-ui';

import { useEffect, useState } from 'react';
import { embedCheckout } from '@bigcommerce/checkout-sdk';
import { useCart } from 'src/context/CartContext';
import { navigate } from 'gatsby';

const Checkout = () => {
  const { theme } = useThemeUI();
  const cartContext = useCart();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (cartContext.state?.cart.redirectUrls.embedded_checkout_url) {
      embedCheckout({
        url: cartContext.state?.cart.redirectUrls.embedded_checkout_url,
        containerId: 'checkout',
        onLoad: () => setLoading(false),
        styles: {
          checklist: {
            /* @ts-ignore:disable-next-line */
            overflow: 'hidden',
          },
          radio: {
            borderColor: get(theme, 'rawColors.darkPink'),
            checked: {
              backgroundColor: get(theme, 'rawColors.pink'),
            },
            backgroundColor: get(theme, 'rawColors.pink'),
          },
          link: {
            color: get(theme, 'rawColors.darkPink'),
          },
          label: {
            color: get(theme, 'rawColors.black'),
          },
          text: {
            color: get(theme, 'rawColors.black'),
          },
          heading: {
            color: get(theme, 'rawColors.black'),
          },
          secondaryHeading: {
            color: get(theme, 'rawColors.black'),
          },
          select: {
            /* @ts-ignore:disable-next-line */
            borderRadius: get(theme, 'radii.0'),
          },
          checkbox: {
            checked: {
              backgroundColor: get(theme, 'rawColors.purple'),
              borderColor: get(theme, 'rawColors.purple'),
            },
          },
          input: {
            /* @ts-ignore:disable-next-line */
            borderRadius: get(theme, 'radii.0'),
            borderColor: get(theme, 'rawColors.lightGray'),
            borderWidth: '2px',
            color: get(theme, 'rawColors.darkGray'),
            transition: '0.2s ease-in-out',
            marginBottom: get(theme, 'space.3'),
            boxShadow: 'none',
            focus: {
              // outline: get(theme, 'space[0]'),
              borderColor: get(theme, 'rawColors.midGray'),
              boxShadow: get(theme, 'shadows.imageSmall'),
            },
            hover: {
              boxShadow: get(theme, 'shadows.imageSmall'),
            },
          },
          button: {
            hover: {
              boxShadow: get(theme, 'shadows.buttonHover'),
            },
            /* @ts-ignore:disable-next-line */
            transition: '0.4s ease-in-out',
            background: get(theme, 'gradients.button')(theme),
            borderRadius: get(theme, 'radii.1'),
            boxShadow: get(theme, 'shadows.button'),
            color: get(theme, 'rawColors.black'),
            border: 'none',
          },
          secondaryButton: {
            backgroundColor: get(theme, 'gradients.buttonSecondary')(theme),
            /* @ts-ignore:disable-next-line */
            borderRadius: get(theme, 'radii.1'),
            boxShadow: get(theme, 'shadows.button'),
            color: get(theme, 'rawColors.black'),
            border: 'none',
          },
        },
      });
    } else if (!cartContext.state?.cartLoading && cartContext.state?.cart.numberItems === 0) {
      navigate('/shop');
    }
  }, [cartContext.state]);

  return (
    <div
      id="checkout"
      sx={{
        position: 'relative',
        height: loading ? '200px' : '',
      }}
    />
  );
};

export default Checkout;
