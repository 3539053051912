/** @jsx jsx */
import { useRef, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { jsx, get, Theme, Container, Paragraph, Box, Grid, Heading } from 'theme-ui';
import { StaticImage } from 'gatsby-plugin-image';
import Button from '../global/Button';
import { Parallax } from 'components/global/utils/Animations';

import Bubble from 'assets/svgs/bubble-1.svg';
const Hero = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <section
      ref={sectionRef}
      sx={{
        pb: '8rem',
        mb: 5,
        background: (t: Theme) => get(t, 'gradients.hero'),
      }}
    >
      <Container>
        <Grid
          columns={[1, 2, null, '2fr 3fr']}
          sx={{
            alignItems: 'center',
            py: [5, null, 6],
          }}
        >
          <Box sx={{ zIndex: 2 }}>
            <Heading as="h1" variant="heading" sx={{ mb: [4, 4, 4] }}>
              Handgemachtes Bodenarbeitszubehör als Unikat
            </Heading>
            <Paragraph variant="subheading" sx={{ mb: 4 }}>
              Individuelle und handgefertigte Produkte für die Bodenarbeit und das Reiten. Jedes Produkt ist ein Unikat
              und zeichnet sich durch seine hohe Qualität aus!
            </Paragraph>
            <Button as={Link} to="/shop">
              Jetzt shoppen
            </Button>
          </Box>
          <Box sx={{ zIndex: 1 }}>
            <Parallax refElement={sectionRef} y={-0.1}>
              <Box sx={{ position: 'relative', marginTop: ['4rem', null, 0] }}>
                <StaticImage
                  sx={{
                    position: 'relative',
                    zIndex: 50,
                    borderRadius: '2rem',
                    width: '80%',
                    top: '1.5rem',
                    right: '2rem',
                    float: 'right',
                    boxShadow: 'image',
                  }}
                  width={530}
                  height={407}
                  src="../../assets/images/horse-hero.jpg"
                  alt="Pferd mit Halfter"
                />
                <Parallax refElement={sectionRef} y={-0.1}>
                  {
                    // Fixes a bug in safari where the svg's gradient is rendered incorrectly on first render
                    mounted && <Bubble sx={{ position: 'absolute', top: 0, left: 0, width: '100%' }} alt="" />
                  }
                </Parallax>
              </Box>
            </Parallax>
          </Box>
        </Grid>
      </Container>
    </section>
  );
};

export default Hero;
