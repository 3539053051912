/** @jsx jsx */
import { Fragment } from 'react';
import { jsx, Container, Flex, Box } from 'theme-ui';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { useState, useCallback, useEffect, useRef } from 'react';
import { globalHistory } from '@reach/router';
import { StaticImage } from 'gatsby-plugin-image';
import { Player } from '@lottiefiles/react-lottie-player';
import { motion, AnimatePresence } from 'framer-motion';
import { useCart } from 'src/context/CartContext';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import useWindowDimensions from 'components/global/utils/useWindowDimensions';
import Cart, { CartButton } from './Cart';
import Button from './Button';
import NavbarToggle from 'assets/json/navbar-toggle.json';
import Banner from './Banner';

import type { AnimationItem } from 'lottie-web';
import type { NavbarTopLevelCategoriesQuery } from 'types/graphql';

const Navbar = () => {
  const [navbarToggled, setNavbarToggled] = useState(false);
  const [cartToggled, setCartToggled] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [lottie, setLottie] = useState<AnimationItem>();

  const cartContext = useCart();
  const scrollBox = useRef<HTMLDivElement>(null);
  const barRef = useRef<HTMLDivElement>(null);
  const bannerRef = useRef<HTMLDivElement>(null);

  const {
    allBigCommerceCategories: { nodes: topLevelCategories },
  } = useStaticQuery<NavbarTopLevelCategoriesQuery>(graphql`
    query NavbarTopLevelCategories {
      allBigCommerceCategories(filter: { parent_id: { eq: 0 } }) {
        nodes {
          id
          name
          custom_url {
            url
          }
        }
      }
    }
  `);

  const { windowHeight } = useWindowDimensions();

  // Disallow scroll for when navbar is opened
  useEffect(() => {
    if (scrollBox?.current) {
      if (navbarToggled || cartToggled) {
        disableBodyScroll(scrollBox.current);
      } else {
        enableBodyScroll(scrollBox.current);
      }
    }
  }, [navbarToggled, cartToggled]);

  // Toggle cart when item is added but not on first render
  useEffect(() => {
    mounted && setCartToggled(true);
    if (cartContext.state?.updatingItem === false || cartContext.state?.addingToCart) setMounted(true);
  }, [cartContext.state]);

  // Close cart and navbar on route change
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setNavbarToggled(false);
        setCartToggled(false);
      }
    });
  }, []);

  useEffect(() => {
    if (lottie) {
      if (navbarToggled) {
        lottie.playSegments([0, 50], true);
      } else {
        lottie.playSegments([50, 0], true);
      }
    }
  }, [navbarToggled]);

  const NavItems = useCallback(() => {
    const item = {
      hidden: {
        opacity: 0,
        transition: {
          when: 'afterChildren',
          staggerChildren: 0.02,
        },
      },
      visible: {
        opacity: 1,
        transition: {
          when: 'beforeChildren',
          staggerChildren: 0.02,
        },
      },
    };

    return (
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: ['flex-start', 'center'],
          position: 'absolute',
          left: 0,
          right: 0,
          ul: {
            listStyleType: 'none',
            paddingInlineStart: 0,
            '.navlink': {
              display: 'block',
              variant: 'text.heading',
              mb: [2, 2, 3],
              textDecoration: 'inherit',
              color: 'inherit',
              transition: '0.2s ease-in-out',
              '&:hover, &:active': {
                color: 'gray',
              },
            },
          },
        }}
      >
        <motion.ul initial="hidden" animate="visible" exit="hidden" variants={item}>
          <motion.li key={`item-${1}`} variants={item}>
            <Link to="/" className="navlink">
              Home
            </Link>
          </motion.li>
          <motion.li key={`item-${2}`} variants={item}>
            <Link to="/shop" className="navlink">
              Shop
            </Link>
          </motion.li>
          <Flex
            key={`item-${3}`}
            sx={{
              mb: 3,
              paddingLeft: 4,
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            {topLevelCategories.map((category) => (
              <motion.li variants={item} key={category.id}>
                <Link
                  sx={{
                    variant: 'text.',
                    fontSize: 3,
                    textDecoration: 'inherit',
                    color: 'inherit',
                    transition: '0.2s ease-in-out',
                    '&:hover, &:active': {
                      color: 'gray',
                    },
                  }}
                  to={category?.custom_url?.url ? `/shop${category.custom_url.url}` : '/shop'}
                >
                  {category.name}
                </Link>
              </motion.li>
            ))}
          </Flex>
          <motion.li key={`item-${4}`} variants={item}>
            <Link to="/sale" className="navlink">
              SALE
            </Link>
          </motion.li>
          <motion.li key={`item-${5}`} variants={item}>
            <Link to="/masstabelle" className="navlink">
              Maßtabelle
            </Link>
          </motion.li>
        </motion.ul>
      </Container>
    );
  }, [navbarToggled]);

  const getBannerUsedSpace = () => {
    if (bannerRef?.current) {
      const bannerHeight = bannerRef.current.offsetHeight;
      const bannerUsedSpace = bannerHeight - window.scrollY;

      return bannerUsedSpace > 0 ? bannerUsedSpace : 0;
    }
    return 0;
  };

  return (
    <Fragment>
      <div ref={bannerRef}>
        <Banner />
      </div>
      <nav
        sx={{
          pt: 'env(safe-area-inset-top)',
          // TODO: Try to change background color to alpha value of theme
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(30px)',
          top: 0,
          position: 'sticky',
          width: '100%',
          zIndex: 100,
        }}
      >
        <Container
          ref={barRef}
          sx={{
            paddingTop: 3,
            paddingBottom: 3,
          }}
        >
          <Flex>
            <Link
              to="/"
              sx={{
                lineHeight: 0,
                height: '4rem',
                position: 'relative',
              }}
            >
              <StaticImage
                sx={{
                  position: 'absolute',
                  top: '-0.3rem',
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
                layout="fixed"
                width={46}
                height={46}
                src="../../assets/images/logo.png"
                alt="Logo"
              />
            </Link>
            {typeof window !== `undefined` &&
              window.location.pathname !== '/checkout' &&
              window.location.pathname !== '/checkout/' && (
                <Flex sx={{ justifyContent: 'flex-end', alignItems: 'center', flexGrow: 1 }}>
                  <Button
                    as={Link}
                    to="/shop"
                    sx={{
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 4,
                      paddingRight: 4,
                      marginRight: 4,
                    }}
                  >
                    Zum Shop
                  </Button>
                  <Box sx={{ marginRight: '2.5rem' }}>
                    <CartButton
                      handleClick={() => {
                        setCartToggled((state) => !state);
                        setNavbarToggled(false);
                      }}
                      toggled={cartToggled}
                    />
                  </Box>
                  <Button
                    variant="noStyle"
                    onClick={() => {
                      setNavbarToggled((state) => !state);
                      setCartToggled(false);
                    }}
                    sx={{ width: '2.5rem' }}
                  >
                    <Player
                      lottieRef={(instance) => setLottie(instance)}
                      speed={2}
                      loop={false}
                      keepLastFrame
                      src={NavbarToggle}
                    />
                  </Button>
                </Flex>
              )}
          </Flex>
        </Container>
        <motion.div
          sx={{
            overflow: 'hidden',
          }}
          animate={{
            height:
              (navbarToggled || cartToggled) && barRef.current && windowHeight
                ? `${windowHeight - barRef.current.getBoundingClientRect().height - getBannerUsedSpace()}px`
                : '0px',
          }}
          transition={{
            duration: 0.3,
          }}
        >
          <Box
            ref={scrollBox}
            sx={{
              pt: 4,
              position: 'relative',
              overflow: 'auto',
              height: '100%',
            }}
          >
            <AnimatePresence>{cartToggled && <Cart handleToggle={setCartToggled} />}</AnimatePresence>

            <AnimatePresence>{navbarToggled && <NavItems />}</AnimatePresence>
          </Box>
        </motion.div>
      </nav>
    </Fragment>
  );
};

export default Navbar;
