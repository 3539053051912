import React from 'react';
import Checkout from 'components/checkout/Checkout';
import PageHeader from 'components/global/PageHeader';
import IconCreditCard from 'assets/svgs/credit-card.svg';
import SEO from 'components/global/Seo';

const CheckoutPage = () => {
  return (
    <>
      <SEO title="Checkout" robots="noindex" />
      <PageHeader
        title="Checkout"
        text="Noch ein paar Angaben und dein Einkauf befindet sich auf dem Weg zu dir"
        icon={<IconCreditCard />}
      />
      <Checkout />
    </>
  );
};

export default CheckoutPage;
