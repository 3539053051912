import * as React from 'react';
import SEO from 'components/global/Seo';
import Hero from 'components/home/Hero';
import FeaturedProducts from 'components/home/FeaturedProducts';
import TextboxImage from 'components/home/TextboxImage';
import Clients from 'components/home/Clients';
import OrderInfo from 'components/home/OrderInfo';

const IndexPage = () => (
  <>
    <SEO title="Home" description="Hochwertige Halfter, Zügel, Stricke und viel mehr in jeder Form und Farbe" />
    <Hero />
    <FeaturedProducts />
    <TextboxImage />
    <Clients />
    <OrderInfo />
  </>
);

export default IndexPage;
