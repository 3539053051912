import React from 'react';
import PageHeader from 'components/global/PageHeader';
import SEO from 'components/global/Seo';
import SaleList from 'components/shop/SaleList';
import { graphql } from 'gatsby';

import IconSale from 'assets/svgs/sale.svg';

import type { SalePageQuery } from 'types/graphql';
import type { PageProps } from 'gatsby';

const SalePage: React.FC<PageProps<SalePageQuery>> = ({ data }) => {
  return (
    <>
      <SEO
        title="Sale"
        description="In unserem Sale Bereich findest du tolle Schnäppchen und sofort versandfertige Produkte. "
      />
      <PageHeader
        title="SALE"
        text="In unserem Sale Bereich findest du tolle Schnäppchen und sofort versandfertige Produkte. "
        icon={<IconSale />}
      />
      <SaleList products={data.allBigCommerceProducts.nodes} />
    </>
  );
};

export const query = graphql`
  query SalePage {
    allBigCommerceProducts(filter: { sale_price: { ne: 0 } }) {
      nodes {
        id
        price
        sale_price
        name
        custom_url {
          url
        }
        images {
          image_sharp {
            childImageSharp {
              gatsbyImageData(width: 270)
            }
          }
          description
          is_thumbnail
        }
      }
    }
  }
`;

export default SalePage;
